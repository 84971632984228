p {
  margin: 0;
}

.swiper-button-prev {
  color: white !important;
}

.swiper-button-next {
  color: white !important;
}
